const url = typeof window !== 'undefined' ? window.location.host : ''

const instance = url.includes('localhost:8000') ?
    'https://irenta.hopto.org/' :
    url.includes('anketa-msk') ?
    'https://msk.ka.irenta.online/' :
    url.includes('anketa-scnd') || url.includes('anketa.scnd') ?
    'https://second.ka.irenta.online/' :
    'https://kz.ka.irenta.online/'

// вставить 'https://msk.ka.irenta.online/' когда появится инстанс мск для анкеты
export const COUNTRY = url.includes('anketa-scnd') || url.includes('anketa.scnd') ? 'rf' : 'kz'
export const PUBLIC_URL = instance + 'public/api'
export const API_URL = instance + 'public/api'
// и поменять BASE_URL когда появятся другие инстансы
export const BASE_URL = url.includes('anketa-scnd') || url.includes('anketa.scnd') ? 'https://second.ka.irenta.online/' : 'https://anketa.irenta.online'
export const BACKEND_URL = instance + 'spd'
export const GET_PRODUCTS = instance + 'spd/anketa/catalog'

export const UPLOADS_FIELD_NAME = 'upload'
export const UPLOADS_ENDPOINT = `${BACKEND_URL}/uploads`
export const DOCUMENTS_REL_PATH = '/documents'
export const SUBMIT_ENDPOINT = `${BACKEND_URL}/submit`
export const SUBMIT_STATUS = `${BACKEND_URL}/status`
export const TOKEN_ENDPOINT = `${BACKEND_URL}/token`
export const ORDER_TOKEN_ENDPOINT = `${API_URL}/account/token`
export const ERRORS_ENDPOINT = `${BACKEND_URL}/error`
export const FORM_EXISTS_ENDPOINT = `${BACKEND_URL}/form-exists/`
export const USER_EXISTS_ENDPOINT = `${BACKEND_URL}/user-exists/`

export const SUBMIT_SUCCESS = `${BASE_URL}/success`
export const SUBMIT_SUCCESS_FISCAL = `${BASE_URL}/allsuccess`

export const CODE_SEND_ENDPOINT = `${BACKEND_URL}/sendcode`
export const CODE_CONFIRM_ENDPOINT = `${BACKEND_URL}/codeconfirm`
export const CODE_CONFIRM_ENABLED = `${BACKEND_URL}/isconfirmation`
