import React, { useState } from 'react'
import TextInput from '../../../../components/Inputs/TextInput'
import DatePicker from '../../../../components/Inputs/DatePicker'
import { UPLOADS_ENDPOINT } from '../../../../server-parameters'
import { yearsAgo, passportInputHandler } from '../../../../utils/helpers'
import {
  validateName,
  validateDate,
  passportDigits,
  passportText,
  smartValidateString,
} from '../../../../validate/validators'
import { Country, ScreenProps } from '../../../../types/common-types'
import { actions, RUSSIAN_PASSPORT } from '../../../../state-actions-reducers'
import FileInput from '../../../../components/Inputs/FileInput'
import { getPassportTypeTranslate } from '../../../../hooks/useGetPassportTypeTranslate'

export default function ChinaForm({
  state,
  lang,
  invalidFields,
  dispatch,
  accountForm,
}: ScreenProps) {
  const [focus, setFocus] = useState('')
  return (
    <>
      <div className="col-2 col-xs-12">
        {/* PASSPORT SERIES / NUMBER */}
        <TextInput
          testName="passportNumber"
          testErrorName="error-passportNumber"
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.passportNumber}
          onChange={passportNumber => {
            dispatch({
              type: actions.PASSPORT_NUMBER,
              data: passportNumber,
            })
          }}
          value={state.passportNumber}
          required
          valid={
            passportDigits(state.passportNumber) &&
            !invalidFields.includes('passportNumber')
          }
          onBlur={() => setFocus('')}
          mask={undefined}
        />
      </div>
      <div className="col-8 col-xs-12">
        {/* PASSPORT_CARD_NUMBER */}
        <TextInput
          testName="passportCardNumber"
          testErrorName="error-passportCardNumber"
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.passportCardNumber}
          onChange={passportCardNumber => {
            dispatch({
              type: actions.PASSPORT_CARD_NUMBER,
              data: passportCardNumber,
            })
          }}
          value={state.passportCardNumber}
          required
          valid={
            validateName(state.passportCardNumber, false) &&
            !invalidFields.includes('passportCardNumber')
          }
          onBlur={() => setFocus('')}
          mask={undefined}
        />
      </div>
      <div className="col-8 col-xs-12">
        {/* PASSPORT ISSUED BY */}
        <TextInput
          testName="passportIssuedBy"
          testErrorName="error-passportIssuedBy"
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.issuedBy}
          onChange={passportIssuedBy =>
            dispatch({
              type: actions.PASSPORT_ISSUED_BY,
              data: passportIssuedBy.toLocaleUpperCase(),
            })
          }
          value={state.passportIssuedBy}
          required
          valid={
            state.passportIssuedBy.length > 0
              ? smartValidateString(state.passportIssuedBy) &&
                !invalidFields.includes('passportIssuedBy')
              : !invalidFields.includes('passportIssuedBy')
          }
          onBlur={() => setFocus('')}
        />
      </div>
      <div className="col-2 col-xs-12">
        {/* PASSPORT ISSUE DATE */}
        <DatePicker
          testName="passportIAt"
          testErrorName="error-passportIAt"
          disabled={!state.agreed}
          lang={lang}
          accountForm={accountForm}
          accountFormKey="passportIAt"
          onChange={data => dispatch({ type: actions.PASSPORT_IAT, data })}
          value={state.passportIAt}
          required
          valid={!invalidFields.includes('passportIAt')}
          onBlur={() => setFocus('')}
          curr={
            state.birthDate
              ? new Date(
                  new Date(state.birthDate).setFullYear(
                    new Date(state.birthDate).getFullYear() + 14
                  )
                )
              : new Date()
          }
          min={
            state.birthDate
              ? new Date(
                  new Date(state.birthDate).setFullYear(
                    new Date(state.birthDate).getFullYear() + 14
                  )
                )
              : new Date(new Date().setFullYear(new Date().getFullYear() - 67))
          }
          max={new Date()}
        />
      </div>
      <div className="col-2 col-xs-12">
        {/* PASSPORT AUTHORITY CODE */}
        <TextInput
          testName="passportAuthorityCode"
          testErrorName="error-passportAuthorityCode"
          disabled={!state.agreed}
          lang={lang}
          content={accountForm.passportAuthorityCode}
          onChange={passportAuthorityCode => {
            const authorityCode = passportInputHandler({
              newValue: passportAuthorityCode,
              oldValue: state.passportAuthorityCode,
              isRussianPassport:
                getPassportTypeTranslate(+state.passportType) ===
                RUSSIAN_PASSPORT,
              kind: 'authority',
            })
            dispatch({
              type: actions.PASSPORT_AUTHORITY_CODE,
              data: authorityCode,
            })
          }}
          value={state.passportAuthorityCode}
          required={true}
          valid={
            passportDigits(state.passportAuthorityCode) &&
            !invalidFields.includes('passportAuthorityCode')
          }
          onBlur={() => setFocus('')}
          focus={focus === 'passportAuthorityCode' || undefined}
        />
      </div>
      <div className="col-4 col-xs-12">
        <FileInput
          testName="mainImageFile"
          testErrorName="error-mainImageFile"
          disabled={!state.agreed}
          lang={lang}
          url={UPLOADS_ENDPOINT}
          autorization={state.formId}
          target={state.passportImages}
          content={accountForm.mainImageFile}
          onChange={data => {
            dispatch({
              type: actions.MAIN_IMAGE_FILE,
              data: data,
            })
          }}
          value={state.mainImageFile[state.mainImageFile.length - 1]}
          valid={!invalidFields.includes('mainImageFile')}
          documentType="Passport1"
        />
      </div>
      <div className="col-4 col-xs-12">
        <FileInput
          testName="addressImageFile"
          testErrorName="error-addressImageFile"
          disabled={!state.agreed}
          lang={lang}
          url={UPLOADS_ENDPOINT}
          autorization={state.formId}
          target={state.passportImages}
          content={accountForm.vizaImageFile}
          onChange={data => {
            dispatch({
              type: actions.ADDRESS_IMAGE_FILE,
              data: data,
            })
          }}
          value={state.addressImageFile[state.addressImageFile.length - 1]}
          valid={!invalidFields.includes('addressImageFile')}
          documentType="Passport2"
        />
      </div>
      <div className="col-2 col-xs-12">
        {/* PASSPORT EXPIRY DATE */}
        <DatePicker
          testName="passportExpiryDate"
          testErrorName="error-passportExpiryDate"
          disabled={!state.agreed}
          lang={lang}
          accountForm={accountForm}
          accountFormKey="passportExpiryDate"
          onChange={data =>
            dispatch({ type: actions.PASSPORT_EXPIRY_DATE, data })
          }
          value={state.passportExpiryDate}
          required
          valid={!invalidFields.includes('passportExpiryDate')}
          onBlur={() => setFocus('')}
          min={new Date()}
          max={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
        />
      </div>
    </>
  )
}
